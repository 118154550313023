<template>
  <div class="container pt15 plr15">
    <van-field
      v-model="writer"
      class="writer-input"
      rows="5"
      type="textarea"
      maxlength="800"
      placeholder="请输入"
      show-word-limit
    />
    <div class="affixs-container bgf flex flex-wrap">
      <div
        class="affix-item relative"
        v-for="(item, index) in affixs"
        :key="index"
        @click="openMedia(item)"
      >
        <video
          v-if="item.type === 'video'"
          fit="fill"
          :src="item.media_url || item.file_url"
        />
        <img
          v-else
          class="cover-img"
          :src="
            showImgCover(
              item.file_url || item.upload_url,
              item.type,
              item.file_suffix
            )
          "
        />
        <img
          v-if="['we_applets', 'video', 'web_page', 'FILE'].includes(item.type)"
          class="play-icon"
          :src="showIconCover(item.type)"
          alt="paly"
        />
        <div
          v-if="['we_applets', 'video', 'web_page', 'FILE'].includes(item.type)"
          class="media-item-title"
        >
          {{
            item.title ||
            item.qw_link_title ||
            item.qw_miniprogram_title ||
            item.file_name
          }}
        </div>
        <div
          class="del-icon flex flex-justify-center flex-align-center"
          @click.stop="onDelete(index)"
        >
          <img src="@/assets/img/quickSend/delete.svg" />
        </div>
      </div>
      <div
        v-if="affixs.length < 9"
        class="affix-item affix-add flex flex-justify-center flex-align-center"
        @click="visible = true"
      >
        <van-icon name="plus" />
      </div>
    </div>
    <div class="footer-handle mt24">
      <van-button class="handle-btn mr15 fw500 cl4a" @click="onReset">
        重置
      </van-button>
      <van-button class="handle-btn fw500" type="primary" @click="onSend">
        发送
      </van-button>
    </div>
    <van-action-sheet
      :show="visible"
      class="affix-panel"
      cancel-text="取消"
      close-on-click-action
      @cancel="() => (visible = false)"
    >
      <div class="panel-content flex">
        <div
          class="option-item flex-1 flex flex-direction-column flex-justify-center flex-align-center"
          @click="addIncident('image')"
        >
          <div
            class="icon-container bgf flex flex-justify-center flex-align-center"
          >
            <img src="@/assets/img/common/picture.svg" />
          </div>
          图片
        </div>
        <!-- <div
          class="option-item flex-1 flex flex-direction-column flex-justify-center flex-align-center"
          @click="addIncident('video')"
        >
          <div class="icon-container bgf flex flex-justify-center flex-align-center">
            <img src="@/assets/img/common/shoot.svg" />
          </div>
          视频
        </div> -->
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import {
  getMarketingTaskDetail,
  getMarketingLabelDetail,
  getKnowledgeDetail,
  backTaskStatus,
  recordEditTask,
  setBackSend
} from '@/service/quickSend.service.js'
import { sendMonitor } from '@/service/quickSend.service.js'
import { ImagePreview, Toast } from 'vant'
import {
  openMiniProgram,
  chooseImage,
  WXAgentRegister,
  sendChatMessageAllType
} from '@/utils/nwechat.js'

export default {
  name: 'task-edit',
  data() {
    return {
      writer: '', // 文案
      affixs: [], // 附件
      dataSource: {}, // 任务详情
      isEdit: false, // 是否有修改
      visible: false, // 附件类型弹窗
      share_data_id: ''
    }
  },
  mounted() {
    console.log('route', this.$route)
    this.getDataSource()
  },
  methods: {
    getDataSource() {
      const { pageType } = this.$route.query
      if (pageType === 'task') {
        this.getMarketingTaskDetail()
      } else {
        this.getMarketingDetail()
      }
    },
    // 获取知识库/标签营销详情
    async getMarketingDetail() {
      const { pageType, sale_script_id } = this.$route.query
      const requestFun =
        pageType === 'knowledge' ? getKnowledgeDetail : getMarketingLabelDetail
      const { data } = await requestFun({ sale_script_id })

      if (data) {
        const { text_content, content_arr } = data
        if (text_content) this.writer = text_content.slice(0, 800)
        const affixs = []
        for (let i = 0; i < content_arr.length; i++) {
          const type = content_arr[i]['type']
          const fileType = {
            IMAGE: 'images',
            LINK: 'web_page',
            VIDEO: 'video',
            MINIPROGRAM: 'we_applets',
            FILE: 'FILE'
          }

          if (type !== 'TEXT') {
            const affixsItem = { ...content_arr[i], type: fileType[type] }
            if (type === 'MINIPROGRAM') {
              affixsItem.appid = affixsItem.qw_miniprogram_appid
              affixsItem.url = affixsItem.qw_miniprogram_page
              affixsItem.title = affixsItem.qw_miniprogram_title
              affixsItem.upload_url = affixsItem.file_url
            }
            affixs.push(affixsItem)
          }
        }
        this.affixs = affixs
        this.dataSource = data
      }
    },
    // 获取任务详情
    async getMarketingTaskDetail() {
      const { id, task_id } = this.$route.query
      const { data } = await getMarketingTaskDetail({ id, task_id })

      if (data) {
        const { content, annex_content_json } = data
        if (content) this.writer = content.slice(0, 800)
        this.affixs =
          annex_content_json?.map((item) => {
            if (
              item.type === 'commodity' ||
              item.type === 'coupon' ||
              item.type === 'activity'
            ) {
              return { ...item, type: 'we_applets' }
            }
            return item
          }) || []
        this.dataSource = data
      }
    },
    // 删除附件
    onDelete(index) {
      if (!this.isEdit) this.isEdit = true
      this.affixs.splice(index, 1)
    },
    //封面图片过滤
    showImgCover(url, type, file_suffix) {
      if (!url && type == 'LINK') {
        return require('@/assets/img/suggestion/default_2x.svg')
      }
      if (!url) {
        return ''
      }
      if (type == 'FILE' && ['doc', 'docx'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/word_2x.svg')
      } else if (type == 'FILE' && ['pdf'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/pdf_2x.svg')
      } else if (type == 'FILE' && ['xlsx', 'xls'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/excel_2x.svg')
      } else if (type == 'FILE' && ['pptx', 'ppt'].includes(file_suffix)) {
        return require('@/assets/img/quickSend/ppt_2x.svg')
      }
      // let baseUrl = url.indexOf('http') === -1 ? 'https://' : ''
      return url
    },
    // 附件封面上的图标展示
    showIconCover(type) {
      const enumType = {
        video: 'VIDEO',
        web_page: 'LINK',
        we_applets: 'MINIPROGRAM',
        FILE: 'LINK'
      }
      return require('@/assets/img/quickSend/' + enumType[type] + '_2x.svg')
    },
    // 点击媒体
    async openMedia(data = {}) {
      if (data.type == 'images') {
        ImagePreview({
          images: [data.file_url || data.upload_url],
          closeable: true
        })
      } else if (data.type == 'video') {
        window.open(data.media_url || data.file_url)
      } else if (data.type == 'we_applets') {
        await openMiniProgram({
          appid: data.appid,
          path: data.url
        })
      } else if (data.type == 'web_page') {
        window.open(data.url || data.qw_link_url)
      } else if (data.type == 'FILE') {
        // console.log('触发文件预览')
        let url = `/preview-page?file=${data.file_url}&type=${data.file_suffix}`
        window.open(url)
      }
    },
    // 添加附件
    async addIncident(key) {
      try {
        if (!this.isEdit) this.isEdit = true
        this.visible = false
        const than = this
        if (key === 'image') {
          const localIds = await chooseImage()
          await WXAgentRegister()
          Toast.loading({
            message: '上传中~',
            duration: 0,
            forbidClick: true
          })
          window.wx.uploadImage({
            localId: localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
            isShowProgressTips: 1, // 默认为1，显示进度提示
            success: function ({ serverId }) {
              // console.log('图片的服务器端ID', serverId) // 返回图片的服务器端ID
              window.wx.getLocalImgData({
                localId: localIds[0], // 图片的localID
                success: function ({ localData }) {
                  // console.log('图片的base64数据', localData) // localData是图片的base64数据，可以用img标签显示
                  Toast.clear()
                  than.affixs.push({
                    media_id: serverId,
                    upload_url: localData,
                    type: 'images'
                  })
                  // console.log('附件数据', than.affixs)
                }
              })
            }
          })
        }
      } catch (error) {
        console.log(error)
        Toast.clear()
      }
    },
    // 重置事件
    onReset() {
      // this.writer = ''
      // this.affixs = []
      this.isEdit = false
      this.getDataSource()
    },
    // 发送前的埋点回调
    async beforeSend(pageType, customer_userid, payable_id) {
      let typeObj = {
        knowledge: 'knowledge',
        task: 'task',
        label: 'tag_marketing'
      }
      let { data, code } = await sendMonitor({
        payable_id: payable_id,
        type: typeObj[pageType],
        user_id: customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },
    // 发送事件
    async onSend() {
      try {
        const {
          pageType,
          task_id,
          sale_script_id,
          customer_userid
        } = this.$route.query
        // 如果发送给个人
        if (customer_userid) {
          await this.beforeSend(
            pageType,
            customer_userid,
            task_id || sale_script_id
          )
        }
        var statu = false
        const list = [...this.affixs]
        if (this.writer) list.unshift({ type: 'text', content: this.writer })

        const fileType = {
          images: 'image',
          video: 'video',
          FILE: 'news',
          web_page: 'news',
          we_applets: 'miniprogram',
          text: 'text'
        }
        for (let i = 0; i < list.length; i++) {
          let type = fileType[list[i].type] || 'text'
          let params = await this.formatSendParams(
            type,
            list[i],
            this.share_data_id
          )
          console.log('发送前参数----3', params)
          let { sendStatus } = await sendChatMessageAllType(type, params, false)
          if (!statu && sendStatus) statu = true
        }
        if (statu) {
          if (pageType === 'task') {
            await this.recordEditTask()
            await this.sendComplete()
          } else {
            await this.setBackSend()
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 回传改变状态-标签营销
    async setBackSend() {
      const { tag_arr } = this.dataSource
      const {
        pageType,
        chat_id,
        customer_userid,
        sale_script_id
      } = this.$route.query

      const body = {
        sale_script_id,
        event_key: '',
        field: chat_id ? 'chat_id' : 'external_contact',
        field_value: customer_userid || chat_id
      }
      if (pageType === 'knowledge') {
        body.event_key = chat_id
          ? 'customer_touch_knowledge_group'
          : 'customer_receive_knowledge'
      } else {
        body.event_key = chat_id
          ? 'customer_touch_tag_group'
          : 'customer_receive_tag'
      }
      if (tag_arr && tag_arr.length)
        body.tag_idx = tag_arr.map((item) => item.tag_id)

      await setBackSend(body)
      wx.closeWindow()
    },
    async sendComplete() {
      // 回传改变状态-任务
      const {
        task_send_record_son_id,
        customer_userid,
        member_userid,
        chat_id
      } = this.$route.query
      const { code } = await backTaskStatus({
        send_record_son_id: task_send_record_son_id,
        member_userid: member_userid,
        external_userid: customer_userid || chat_id,
        send_status: 'success'
      })

      if (code == 200) {
        Toast.success('发送成功!')
        setTimeout(() => {
          wx.closeWindow()
        }, 1500)
        // this.$router.back()
      }
    },
    // 分享(发送)参数配置
    formatSendParams(type, item, share_data_id) {
      console.log('附件item', item)
      const { task_id, pageType } = this.$route.query
      // 缺少http头部，补充
      if (item.file_url && item.file_url.indexOf('http') === -1) {
        item.file_url = 'https://' + item.file_url
      }

      let params = {}
      if (['image', 'video'].includes(type)) {
        params[type] = {
          mediaid: item.media_id
        }
      } else if (type == 'news') {
        // 普通h5
        if (item.type != 'FILE') {
          let imgUrl =
            item.upload_url ||
            item.file_url ||
            'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
          params[type] = {
            link: item.url || item.qw_link_url, //H5消息页面url 必填
            title: item.title || item.qw_link_title, //H5消息标题
            desc: item.description || item.qw_link_desc || '', //H5消息摘要
            imgUrl: imgUrl //H5消息封面图片URL
          }
        } else {
          // 文件类型附件，当做h5发出去
          // 环境不同需要更换
          let baseURL = location.origin + '/preview-page?file='
          let imageUrl = this.getFileCover(item)
          params[type] = {
            link: baseURL + item.file_url + `&type=${item.file_suffix}`, //H5消息页面url 必填
            title: item.file_name, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imageUrl //H5消息封面图片URL
          }
        }
      } else if (type == 'miniprogram') {
        let path = item.url
        const pathIndex = path.indexOf('?')
        if (pathIndex === -1) {
          if (path.indexOf('.html') === -1) {
            path = `${path}.html`
          }
        } else {
          if (path.indexOf('.html') === -1) {
            let pathArray = path.split('')
            const index = pathArray.indexOf('?')
            pathArray.splice(index, 0, '.html')
            path = pathArray.join('')
          }
        }
        // 任务类型
        if (pageType === 'task') {
          if (pathIndex === -1) {
            path = `${path}?task_id=${task_id}`
            // 商品附件
            if (item.goods_id) path = `${path}&goods_id=${item.goods_id}`
            // 优惠券附件
            if (item.coupon_id) path = `${path}&coupon_id=${item.coupon_id}`
          } else {
            path = `${path}&task_id=${task_id}`
            // 商品附件
            if (item.goods_id) path = `${path}&goods_id=${item.goods_id}`
            // 优惠券附件
            if (item.coupon_id) path = `${path}&coupon_id=${item.coupon_id}`
          }
        }

        // 如果存在埋点id加入埋点id
        if (share_data_id)
          path = `${path}${
            path.indexOf('?') == -1 ? '?' : '&'
          }share_data_id=${share_data_id}`

        params[type] = {
          appid: item.appid, //小程序的appid
          title: item.title, //小程序消息的title
          imgUrl: item.upload_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page: path
        }
      } else if (type == 'text' && item.content) {
        // params.content = item.content
        params[type] = {
          content: item.content
        }
      } else {
        return ''
      }
      // console.log(2, params)
      return Promise.resolve(params)
    },
    // 发送时获取图片封面
    getFileCover(item) {
      if (['doc', 'docx'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
      } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
      } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
      } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
      } else {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      }
    },
    // 记录编辑过的任务
    async recordEditTask() {
      const { writer, dataSource, isEdit, affixs } = this
      const { task_id, customer_userid, chat_id } = this.$route.query
      if (writer === dataSource.content?.slice(0, 800) && !isEdit) {
        return false
      }

      const body = {
        task_id,
        content: writer,
        annex_content_json: affixs,
        external_userid: customer_userid || chat_id
      }
      await recordEditTask(body)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;

  .writer-input {
    border-radius: 5px 5px 0 0;
    padding: 14px 12px 8px 12px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    :deep(.van-field__control) {
      color: #171717;
      font-weight: 400;
    }
    :deep(.van-field__word-limit) {
      color: #4a4a4a;
      font-size: 13px;
      font-weight: 400;
    }
  }
  .affixs-container {
    min-height: 122px;
    padding: 12px 0 0 12px;
    border-radius: 0 0 5px 5px;
    box-sizing: border-box;

    .affix-item {
      width: calc((100vw - 78px) / 3);
      height: calc((100vw - 78px) / 3);
      box-sizing: border-box;
      overflow: hidden;
      margin-right: 12px;
      margin-bottom: 12px;
      border-radius: 5px;

      video {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: fill;
      }
      .play-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 36px;
        height: 36px;
        z-index: 5;
      }
      .media-item-title {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #fff;
        text-align: center;
        background: rgba(0, 0, 0, 0.4);
      }
      .del-icon {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        width: 24px;
        height: 24px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .affix-add {
      font-size: 20px;
      background-color: #f8f8f8;
    }
  }
  :deep(.affix-panel) {
    .panel-content {
      height: 138px;
      background: #efefef;

      .option-item {
        height: 100%;
        color: #6c6c6c;
        font-size: 10px;

        .icon-container {
          width: 57px;
          height: 56px;
          border-radius: 6px;
          margin-bottom: 6px;
        }
      }
    }
    .van-action-sheet__gap {
      height: 0;
    }
    .van-action-sheet__cancel {
      opacity: 0.9;
      font-size: 17px;
      font-weight: 400;
      color: #000000;
    }
  }
  .footer-handle .handle-btn {
    width: calc((100% - 15px) / 2);
    height: 44px;
    border-radius: 5px;
  }
}
</style>
